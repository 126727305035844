const { isFromDtsFile } = require("@angular/compiler-cli/src/ngtsc/util/src/typescript");

	console.log("hhhhhhhhh");

	function translate()
	{
		console.log("Traslate!");
		let myLanguageTemp = window.localStorage.getItem("SpiritULangLanding");
		if(myLanguageTemp == null) myLanguageTemp ='es_MX';
		$('#selectLanguageDropdown').localizationTool('translate', myLanguageTemp);
		if(myLanguageTemp == "en_GB")
		{
			$("#imageHolder").attr("src", "assets/celulares-anuncio_EN.png");
		}
		else if( countryLanguageCode == "es_MX")
		{
			
			$("#imageHolder").attr("src", "assets/celulares-anuncio.png");
		}
		
	}

	function startAll()
	{
		//$('#selectLanguageDropdown').localizationTool("destroy");
		$('#selectLanguageDropdown').empty();

		setTimeout(function(){
			console.log("main load");
			$('#selectLanguageDropdown').localizationTool({
				'defaultLanguage' : 'es_MX',
				/* do not throw error if a selector doesn't match */
				'ignoreUnmatchedSelectors': false,
				/* show the flag on the widget */
				'showFlag' : true,
				/* show the language on the widget */
				'showLanguage': false,
				/* show the country on the widget */
				'showCountry': false,
				/* format of the language/country label */
				'labelTemplate': '{{country}} {{(language)}}',
				'languages' : {
					'es_MX' : {
						'country' : 'Mexico',
						'language' : 'Spanish',
						'countryTranslated': 'México',
						'languageTranslated': 'Español',
						'flag' : {
							'class': 'flag flag-mx'
						}
					},
					'en_GB' : {
						'country' : 'United Kingdom',
						'language': 'English',
						'countryTranslated' : 'United Kingdom',
						'languageTranslated': 'English',
						'flag': {
							'class' : 'flag flag-gb'
						}
					},
				},
				
				'strings' : {
					'id:lan_inicio': {
						'en_GB' : 'Home'
					},
					'id:lan_nosotros': {
						'en_GB' : 'About us'
					},
					'id:lan_anuncios': {
						'en_GB' : 'Ads'
					},
					'id:lan_anuncios2': {
						'en_GB' : 'How to create ads?'
					},
					'id:lan_tutoriales': {
						'en_GB' : 'Tutorials'
					},
					'id:lan_tutoriales2': {
						'en_GB' : 'Tutorials'
					},
					'id:lan_encuentraProd': {
						'en_GB' : 'Find alternative products and services at Spirit Ü'
					},
					'id:lan_descargaLaApp': {
						'en_GB' : 'Download the App'
					},
					'id:lan_llegaAmasSpirits': {
						'en_GB' : 'Reach more spirits with a single click'
					},
					'id:lan_anunciaTusProd': {
						'en_GB' : "Advertise your products or services here"
					},
					 'id:lan_formaPartede': {
						'en_GB' : "Be part of the largest community in Mexico Spirit Ü"
					},
					'id:Nosotros2': {
						'en_GB' : "About us"
					},
					'id:lan_queEsSpirit': {
						'en_GB' : "What is Spirit Ü?"
					},
					'id:lan_plataformaGratuita': {
						'en_GB' : "Spirit Ü is a free platform where people will connect you with alternative services and products. You call what you vibrate and with Spirit Ü you can find people who vibrate on your same frequency."
					},
					'id:lan_queSonLosGuias': {
						'en_GB' : "What are the Ü Guides?"
					},
					'id:lan_convierteteGuia': {
						'en_GB' : "Become a Guide Ü"
					},
					'id:lan_losGuiasUson': {
						'en_GB' : "The Ü Guides are local people or travelers who want to offer an alternative product and/or service, we know that your ads will be unique and we believe that they deserve to be better known by people."
					},
					'id:lan_queSonSpirits': {
						'en_GB' : "What are Spirits?"
					},
					'id:lan_losSpiritsSon': {
						'en_GB' : "They are people who are looking for something out of the ordinary and/or their lifestyle is more alternative and in Spirit Ü you will find everything you are looking for, you just need a yes to become a Spirit."
					},
					'id:lan_encuentraTodoLoQue': {
						'en_GB' : "Find everything you are looking for"
					},
					'id:lan_seUnGuia': {
						'en_GB' : "Be a Guide Ü or a Spirit, this is the time to register and promote your products or services or find them. Soon you will find Spirit Ü in Google Play for Android and in App"
					},
					'id:lan_ofreceTusServicios': {
						'en_GB' : "Offer your services and products"
					},
					'id:lan_registrateCreaTuPerfil': {
						'en_GB' : "Register on our website, create your profile and become a Ü Guide. Let more people know you, expand your possibilities and take your business to the next level!"
					},
					'id:lan_tienesAlguna': {
						'en_GB' : "Do you have any doubt or comment? contacto@appspiritu.com"
					},
					'id:lan_comoSer': {
						'en_GB' : "How to be a Guide Ü"
					},
					'id:lan_paso1': {
						'en_GB' : "Step 1"
					},
					'id:lan_Registrate1': {
						'en_GB' : "Register"
					},
					'id:lan_paso2': {
						'en_GB' : "Step 2"
					},
					'id:lan_Publica': {
						'en_GB' : "Publish"
					},
					'id:lan_paso3': {
						'en_GB' : "Step 3"
					},
					'id:lan_Comparte': {
						'en_GB' : "Share"
					},
					'id:lan_soloTienesQue': {
						'en_GB' : "To become a Guide Ü or a Spirit, you just have to download the app and register by entering the requested information"
					},
					'id:lan_publicaTuservicio': {
						'en_GB' : "You have the opportunity to upload up to 3 different products or services that you offer. Once it’s uploaded we will review them to make sure it complies with our policy. Once approved your ad can be seen by everyone."
					},
					'id:lan_queremosQueTodos': {
						'en_GB' : "Share your ads! We want everyone to discover your essence and all the magic you have to offer."
					},
					'id:lan_loUnicoQue': {
						'en_GB' : "All you need is a yes."
					},
					'id:lan_OfrecerTus': {
						'en_GB' : "Register and start offering your services and products"
					},
					'id:lan_yTuEresGuia': {
						'en_GB' : "What about you? Are you a Guide Ü or a Spirit?"
					}
				},
				/*
				 * A callback called whenever the user selects the language
				 * from the dropdown menu. If false is returned, the
				 * translation will not be performed (but just the language
				 * will be selected from the widget).
				 *
				 * The countryLanguageCode is a string representing the
				 * selected language identifier like 'en_GB'
				 */
				'onLanguageSelected' : function (countryLanguageCode) 
				{ 
					console.log("language!" , countryLanguageCode);
					window.localStorage.setItem("SpiritULangLanding", countryLanguageCode);
					window.location.reload();
					if(countryLanguageCode == "en_GB")
					{
						$("#imageHolder").attr("src", "assets/logoBrand.png");
					}
					else if( countryLanguageCode == "es_MX")
					{
						
						$("#imageHolder").attr("src", "assets/celulares-anuncio.png");
					}
					return true; 
				}
				});
				setTimeout(function(){
					translate();
				},100);

		},100)

	}

	/*setTimeout(function(){
		startAll();
	},500);
	

	setTimeout(function(){
		translate();
	},2500);*/
